:root {
  --bg-color: #0c0c0c;
  --primary-color: #0d0d0d;
  --secondary-color: #fff;
  --text-color: #fff;
  --text-color-2: #fff;
  --overlay-color: rgb(12 12 12 / 63%);
}

[data-theme="light"] {
  --bg-color: #ffffff;
  --primary-color: #ffffff;
  --secondary-color: #000;
  --text-color: #000;
  --text-color-2: #000;
  --overlay-color: rgb(255 255 255 / 70%);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Marcellus;
}

p {
  word-break: break-word;
  hyphens: auto;
}
