.intro_sec {
    height: calc(100vh - 60px);
    min-height: 700px;
    height: 100vh;
    margin-top: -60px;
}

@media (max-width:991.98px) {
    .intro_sec {
        display: block;
        height: auto!important;
    }
}

.intro_sec .text,
.intro_sec .h_bg-image {
    width: 50%;
}

@media (max-width:991.98px) {
    .intro_sec .text,
    .intro_sec .h_bg-image {
        width: 100%;
    }
}

.intro_sec .intro {
    max-width: 450px;
    margin: 0 auto;
}

@media (max-width:991.98px) {
    .intro_sec .intro {
        max-width: 700px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.intro_sec .intro .feature .wrap-icon {
    background: 0 0!important;
    width: auto;
    height: auto;
    margin-bottom: 0;
}

.intro_sec .intro .feature .wrap-icon svg {
    color: #5cccc9;
}

.intro_sec .text h1 {
    font-size: 30px;
    margin-bottom: 50px;
    font-weight: 700;
}

.intro_sec .text h3 {
    font-size: 16px;
    font-weight: 700;
}

.intro_sec .h_bg-image {
    background-size: cover;
    background-position: center;
    min-height: 700px;
    position: relative;
}


.intro_sec .h_bg-image .block {
    bottom: 50vh;
    max-width: 220px;
    padding: 30px;
    left: -110px;
    position: absolute;
    width: 250px;
    height: 220px;
    border-radius: 100%;
    /* mix-blend-mode: multiply; */
    background-color: #2f514e;
    z-index: 9999999999;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

@media (max-width:991.98px) {
    .intro_sec .h_bg-image {
        height: 600px;
        min-height: 75vh;
        margin-bottom: 30px;
    }
    .intro_sec .h_bg-image .block {
        top: unset;
        bottom: 0;
        width: 100%;
        margin: 0;
        left: 50%;
        transform: translate(-50%, 50%);
    }
}

.intro_sec .h_bg-image .block .wrap-icon {
    font-size: 40px;
    color: var(--text-color);
    margin-bottom: 10px;
    display: block;
}

.intro_sec .h_bg-image .block h3 {
    font-size: 14px;
    color: var(--text-color);
    letter-spacing: .1rem;
    font-weight: 700;
    margin-bottom: 15px;
}

.intro_sec .h_bg-image .block p {
    font-size: 14px;
    line-height: 1.3;
    color: var(--text-color)
}

.image-profile {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    margin: 20px;
    object-fit: cover;
    object-position: center right;
  }